import React from 'react'

import Layout from '../components/layout'
import wappen_alania from '../images/wappen_alania.jpg'
import wappen_armino_hercynia from '../images/wappen_armino_hercynia.jpg'
import wappen_germania from '../images/wappen_germania.jpg'
import wappen_hansea from '../images/wappen_hansea.jpg'
import wappen_tuisko from '../images/wappen_tuisko.jpg'


const MembersPage = () => (
  <Layout>
    <h1 className="mt-5">Mitgliedsbünde</h1>
    <p>Eine Übersicht über die Mitgliedsbünde des Marburger Konvents studentischer Verbindungen.</p>

    <div className="container marketing">

      <hr className="featurette-divider" />

      <div className="row featurette">
        <div className="col-md-2">
          <img className="featurette-image img-fluid mx-auto" src={wappen_alania} alt="Wappen Alania" />
        </div>
        <div className="col-md-10">
          <h2 className="featurette-heading">Turnerschaft&nbsp;
          <span className="text-muted">Alania</span>
          </h2>
          <p>Wir sind eine Gemeinschaft aus Studenten und ehemalige Studenten der TU Braunschweig. Da wir im Jahr 1888 gegründet wurden, ist die Zahl der ehemaligen Studenten, Alte Herren genannt, deutlich größer als die der Studierenden. Im Moment bestehen wir aus ca. 130 Alte Herren und 10 bis 15 Studenten.</p>
          <h5>Gründungsdatum:</h5>
          <p>Montag, 23 Januar, 1888</p>
          <h5>Homepage:</h5>
          <p><a href="https://turnerschaft-alania.de/">turnerschaft-alania.de</a></p>
          <h5>Wikipedia:</h5>
          <p><a href="https://de.wikipedia.org/wiki/Turnerschaft_Alania_Braunschweig">Artikel</a></p>
          <h5>Standort:</h5>
          <p>Turnerschaft Alania im MK<br />
            Pockelsstraße 5<br />
            38106 Braunschweig<br />
            Deutschland<br />
            Siehe Karte: <a href="https://maps.google.com/?q=Pockelsstra%C3%9Fe+5%2C+38106%2C+Braunschweig%2C+de">Google Maps</a>
          </p>
        </div>
      </div>
      <hr className="featurette-divider" />

      <div className="row featurette">
        <div className="col-md-2">
          <img className="featurette-image img-fluid mx-auto" src={wappen_armino_hercynia} alt="Wappen Armino-Hercynia" />
        </div>
        <div className="col-md-10">
          <h2 className="featurette-heading">Turnerschaft&nbsp;
          <span className="text-muted">Armino-Hercynia</span>
          </h2>
          <p>Unser Haus in der Wilhelmshavener Straße 10 ist der Mittelpunkt des Verbindungslebens . Es dient als Treffpunkt für Bundesbrüder, bietet genügend Platz für Veranstaltungen aller Art. Neben 8 günstigen Studentenbuden gibt es hier noch ein großes Wohnzimmer, zwei gut ausgestattete Arbeitszimmer mit Bibliothek und eine riesige Terrasse.</p>
          <h5>Gründungsdatum:</h5>
          <p>Samstag, 29 November, 1873</p>
          <h5>Homepage:</h5>
          <p><a href="https://armino-hercynia.de">armino-hercynia.de</a></p>
          <h5>Standort:</h5>
          <p>Turnerschaft Armino-Hercynia im MK<br />
            Wilhelmshavener Straße 10<br />
            30167 Hannover<br />
            Deutschland<br />
            Siehe Karte: <a href="https://maps.google.com/?q=Wilhelmshavener+Stra%C3%9Fe+10%2C+30167%2C+Hannover%2C+de">Google Maps</a>
          </p>
        </div>
      </div>
      <hr className="featurette-divider" />

      <div className="row featurette">
        <div className="col-md-2">
          <img className="featurette-image img-fluid mx-auto" src={wappen_germania} alt="Wappen Germania" />
        </div>
        <div className="col-md-10">
          <h2 className="featurette-heading">Turnerschaft&nbsp;
          <span className="text-muted">Germania</span>
          </h2>
          <p>Aktivitas und Altherrenverband bilden gemeinsam die am 01. Mai 1877 gegründete Turnerschaft Germania zu Bonn. Im Zusammenleben der Aktivitas auf dem Verbindungshaus in Bonn sowie durch gemeinsame Veranstaltungen von Aktivitas und Altherrenverband führen wir unsere Traditionen fort und erhalten sie für zukünftige Studentengenerationen.</p>
          <h5>Gründungsdatum:</h5>
          <p>Dienstag, 1 Mai, 1877</p>
          <h5>Homepage:</h5>
          <p><a href="https://t-germania.de">t-germania.de</a></p>
          <h5>Standort:</h5>
          <p>Turnerschaft Germania im MK<br />
            Argelanderstraße 22<br />
            53115 Bonn<br />
            Deutschland<br />
            Siehe Karte: <a href="https://maps.google.com/?q=Argelanderstra%C3%9Fe+22%2C+53115%2C+Bonn%2C+de">Google Maps</a>
          </p>
        </div>
      </div>
      <hr className="featurette-divider" />

      <div className="row featurette">
        <div className="col-md-2">
          <img className="featurette-image img-fluid mx-auto" src={wappen_hansea} alt="Wappen Hansea" />
        </div>
        <div className="col-md-10">
          <h2 className="featurette-heading">Turnerschaft&nbsp;
          <span className="text-muted">Hansea</span>
          </h2>
          <p>Gemäß unserem Wahlspruch "Einer für Alle, Alle für Einen" verbindet uns eine generationsübergreifende lebenslange Freundschaft, der sog. "Lebensbund".</p>
          <h5>Gründungsdatum:</h5>
          <p>Freitag, 10 November, 1848</p>
          <h5>Homepage:</h5>
          <p><a href="https://t-hansea.de">t-hansea.de</a></p>
          <h5>Standort:</h5>
          <p>Turnerschaft Hansea im MK<br />
            Wilhelmshavener Str. 12<br />
            30167 Hannover<br />
            Deutschland<br />
            Siehe Karte: <a href="https://maps.google.com/?q=Wilhelmshavener+Str.+12%2C+30167%2C+Hannover%2C+de">Google Maps</a>
          </p>
        </div>
      </div>
      <hr className="featurette-divider" />

      <div className="row featurette">
        <div className="col-md-2">
          <img className="featurette-image img-fluid mx-auto" src={wappen_tuisko} alt="Wappen Tuisko" />
        </div>
        <div className="col-md-10">
          <h2 className="featurette-heading">Turnerschaft&nbsp;
          <span className="text-muted">Tuisko</span>
          </h2>
          <p>Die Turnerschaft Tuisko wurde am 04. April 1892 an der damaligen Königlich Technischen Hochschule Hannover gegründet. Seit ihrer Gründung sieht die Turnerschaft Tuisko ihre Hauptaufgabe darin, ihren Mitgliedern eine ethische und gesellschaftliche Orientierung zu bieten. Ihre Mitglieder wollen sich eine von Verantwortung getragene Charakterhaltung und Lebensführung zu eigen machen, um so ihren Beitrag zu Deutschland und Europa leisten zu können. Dies verdeutlich sich auch in ihrem Wahlspruch: "Pro patria est dum ludere videmur"- Zu deutsch: "Es ist fürs Vaterland, während wir zu spielen scheinen." Dieses Ideal versucht sie durch das Festhalten an bestimmten Prinizipien zu erreichen: Dem Hochschul-, Lebensbund-, Convents-, und Leistungs- und Bildungsprinzips. Durch diese zeitlosen Prinzipien vschafft die Turnerschaft Tuisko ein Band zwischen der Tradition und Gegenwart und hält sich auch immer der Zukunft offen. So leben wir seit mehr als einem Jahrhundert mit- und voneinander, verfolgen gemeinsam unsere Ziele und feiern besondere Ereignisse.</p>
          <h5>Gründungsdatum:</h5>
          <p>Montag, 4 April, 1892</p>
          <h5>Homepage:</h5>
          <p><a href="http://tuisko.de/">tuisko.de</a></p>
          <h5>Standort:</h5>
          <p>Turnerschaft Tuisko<br />
            An der Christuskirche 13<br />
            30167 Hannover<br />
            Deutschland<br />
            Siehe Karte: <a href="https://maps.google.com/?q=An+der+Christuskirche+13%2C+30167%2C+Hannover%2C+de">Google Maps</a>
          </p>
        </div>
      </div>
      <hr className="featurette-divider" />


    </div>

  </Layout>
)

export default MembersPage 